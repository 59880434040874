import { each } from 'lodash';

const createPaylineMatrix = (payline) => {
  const paylineMatrix = [];
  each(payline, (symbol, index) => {
    paylineMatrix.push([index + 1, symbol + 1]);
  });

  return paylineMatrix;
};

export default {
  createPaylinesMatrix(paylines) {
    const paylinesMatrix = [];
    each(paylines, (payline) => {
      paylinesMatrix.push({
        matrix: createPaylineMatrix(payline),
      });
    });
    return paylinesMatrix;
  },
};
