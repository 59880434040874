<template>
  <div class="slotReel"
       :style="reelStyle">
    <div class="slotReelFrame"
         ref="reelFrame">
        <SlotSymbol :key="symbolIndex"
                    :symbol="symbol"
                    :reel-index="index"
                    :symbol-index="symbolIndex"
                    v-for="(symbol, symbolIndex) in spinSymbols" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { clone, has } from 'lodash';
import SlotSymbol from '@/components/SlotSymbol';

export default {
  name: 'SlotReel',
  components: {
    SlotSymbol,
  },
  data() {
    return {
      spinSymbols: null,
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    symbols: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters([
      'assetUrl',
      'assets',
      'columnSize',
    ]),
    reelStyle() {
      const style = {
        width: `${(100 / this.symbols.length)}%`,
      };

      if (has(this.assets, 'reel_background')) {
        style.backgroundImage = this.assetUrl('reel_background');
        style.backgroundRepeat = 'no-repeat';
        style.backgroundSize = '100% 100%';
      }

      return style;
    },
  },
  methods: {
    ...mapActions([
      'setColumnSize',
    ]),
    setReelSize() {
      const reel = this.$refs.reelFrame;
      const reelSize = {
        width: reel.getBoundingClientRect().width,
        height: reel.getBoundingClientRect().height,
      };
      setTimeout(() => {
        this.setColumnSize(reelSize);
      });
    },
    setListeners() {
      window.addEventListener('resize', this.setReelSize);
      window.addEventListener('orientationchange', this.setReelSize);
    },
  },
  mounted() {
    this.spinSymbols = clone(this.symbols);
    this.setListeners();
    if (!this.columnSize) {
      this.setReelSize();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setReelSize);
    window.removeEventListener('orientationchange', this.setReelSize);
  },
};
</script>

<style scoped lang="scss">
  .slotReel {
    .slotReelFrame {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
</style>
