<template>
  <div class="slotSymbol"
       :class="versionType">
    <div class="slotSymbolAsset"
         ref="symbol">
      <img :src="getSymbol(symbol)" />
      <SlotCounter v-if="isNumberShown"
                    display="static"
                   :counter="getNumber()" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  defaultTo,
  find,
  has,
  isObject,
} from 'lodash';
import SlotCounter from '@nsftx/casino-client-sdk/src/components/SlotCounter';

export default {
  name: 'SlotSymbol',
  components: {
    SlotCounter,
  },
  props: {
    reelIndex: {
      type: Number,
    },
    symbol: {
      required: true,
    },
    symbolIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters([
      'asset',
      'config',
      'round',
      'symbolSize',
      'versionType',
    ]),
    symbolNumberSuffix() {
      return isObject(this.symbol) ? this.symbol.number : this.symbol;
    },
    isCashSymbol() {
      return this.config.collect?.cashSymbol === this.symbolNumberSuffix;
    },
    isMultiplierSymbol() {
      return this.config.collect?.multiplierSymbol === this.symbolNumberSuffix;
    },
    isNumberShown() {
      return this.isCashSymbol || this.isMultiplierSymbol;
    },
  },
  methods: {
    ...mapActions([
      'setSymbolSize',
    ]),
    getNumber() {
      const { cashSymbolReelPosition, multiplierSymbolReelPosition } = this.round.collect;
      if (this.isCashSymbol) {
        const cashDetails = find(cashSymbolReelPosition,
          (reelDetail) => reelDetail[1] === this.reelIndex && reelDetail[2]
          === this.symbolIndex);
        return cashDetails[0];
      }
      if (this.isMultiplierSymbol) {
        return multiplierSymbolReelPosition[0][0];
      }
      return null;
    },
    getCollectSymbol(symbolIndex) {
      if (has(this.config, 'collect')) {
        const symbol = Object.keys(this.config.collect)
          .find((key) => this.config.collect[key] === symbolIndex);
        if (symbol === 'cashSymbol') {
          return defaultTo(this.asset('symbol_cc'), this.asset('symbol_cc_0'));
        }
        if (symbol === 'collectSymbol') {
          return defaultTo(this.asset('symbol_c'), this.asset('symbol_c_0'));
        }
        if (symbol === 'multiplierSymbol') {
          return defaultTo(this.asset('symbol_cm'), this.asset('symbol_cm_0'));
        }
      }
      return null;
    },
    getSpecialSymbol(symbolIndex) {
      if (symbolIndex === 0) {
        return defaultTo(this.asset('symbol_0'), this.asset('symbol_0_0'));
      }
      if (symbolIndex === -1) {
        return defaultTo(this.asset('symbol_b'), this.asset('symbol_b_0'));
      }
      return this.getCollectSymbol(symbolIndex);
    },
    getStandardSymbol(symbolIndex) {
      return this.asset(`symbol_${symbolIndex}`);
    },
    getSymbol(symbol) {
      const symbolIndex = isObject(symbol) ? symbol.number : symbol;
      return symbolIndex <= 0 ? this.getSpecialSymbol(symbolIndex)
        : this.getStandardSymbol(symbolIndex);
    },
    setSymbolDimensions() {
      const symbolElement = this.$refs.symbol;
      const symbolSize = {
        width: symbolElement.getBoundingClientRect().width,
        height: symbolElement.getBoundingClientRect().height,
      };
      this.setSymbolSize(symbolSize);
    },
    setListeners() {
      window.addEventListener('resize', () => {
        this.setSymbolDimensions();
      });
    },
  },
  mounted() {
    if (!this.symbolSize) {
      this.setSymbolDimensions();
    }
    this.setListeners();
  },
};
</script>

<style scoped lang="scss">
  .slotSymbol {
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lost {
    opacity: 0.5;
  }

  .counter {
    width: 55%;
  }

  .slotSymbolAsset {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
</style>
