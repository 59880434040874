export default {
  parse(query) {
    const parsed = {};
    query.substring(1).replace(/[?&]?([^=]+)=([^&]*)/g, (m, key, value) => {
      parsed[window.decodeURIComponent(key)] = window.decodeURIComponent(value);
    });

    return parsed;
  },
};
