export default {
  columnSize: undefined,
  game: undefined,
  params: undefined,
  paylines: undefined,
  round: undefined,
  symbolSize: undefined,
  versionType: undefined,
  winLineSettings: {
    alpha: 1,
    color: '0xffe843',
    cap: 'round',
    join: 'round',
  },
};
