<template>
  <div class="template">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'RoundPreview',
};
</script>
