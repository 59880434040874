<template>
  <div class="displayContainer"
       :class="[{ isLoading: isRoundLoading }, versionType]">
    <div class="displayWrapper"
         v-if="isRoundLoaded">
      <div class="sectionHeader">{{params.selectionLabel}}</div>
      <div class="bets">
        <div class="bet"
             :key="bet.index"
             v-for="bet in bets">
          <div class="ball"
               :class="[ball.isHit ? ball.color : undefined]"
               :key="ball.index"
               v-for="ball in bet.balls">
            {{ball.number}}
          </div>
          <div class="additionalInfo">
            <div class="payment">
              <div>{{params.stakeLabel}}</div>
              <div class="amount">{{bet.betAmount.toFixed(2)}}</div>
            </div>
            <div v-if="bet.winAmount"
                 class="payout">
              <div>{{params.winLabel}}</div>
              <div class="amount">{{bet.winAmount.toFixed(2)}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sectionHeader">{{params.roundsResultsLabel}}</div>
      <div class="result">
        <div class="ball"
             :class="[result.color]"
             :key="result.position"
             v-for="result in results">
          {{result.number}}
          <div v-if="result.bonusMultiplier > 1"
               class="bonus">
          </div>
        </div>
      </div>
    </div>
    <div class="loader"
         v-else>
    </div>
  </div>
</template>

<script>
import { findKey, findIndex, map } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'SlingshotBets',
  computed: {
    ...mapGetters([
      'config',
      'isRoundLoaded',
      'params',
      'round',
      'versionType',
    ]),
    isRoundLoading() {
      return !this.isRoundLoaded;
    },
    results() {
      return this.round ? this.round.result : [];
    },
    bets() {
      return this.round ? map(this.round.bets, (item, i) => {
        const bet = {
          index: i,
          betAmount: item.betAmount,
          winAmount: item.winAmount || null,
          isWon: item.isWon,
          balls: map(item.value, (value, j) => {
            const ball = {
              index: j,
              number: value,
              color: findKey(this.config.colors, (color) => color.numbers.indexOf(value) > -1),
              isHit: findIndex(this.results, (result) => result.number === value) > -1,
            };

            return ball;
          }),
        };

        return bet;
      }) : [];
    },
  },
  methods: {},
};
</script>
<style lang="scss">
  @import "../style/mixins/scrollbar";
  body {
    @include scrollbar();
  }
</style>

<style scoped lang="scss">
  @import "../style/mixins/loader";
  @import "../style/mixins/spin";

  .displayContainer {
    &.isLoading {
      background: grey;
    }
  }

  .displayWrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .sectionHeader {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
  }

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    @include loader(60px, 4px, #ffeb3b, 2s);
  }

  .bet {
    padding-bottom: 15px;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
    margin-bottom: 15px;
  }

  .additionalInfo {
    width: 100%;
    display: flex;
    flex-direction: row;

    .payment,
    .payout {
      display: flex;
      flex-direction: column;
      margin: 2px 10px 0px 2px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      .amount {
        font-weight: bold;
        color: #fff;
      }
    }
  }

  .ball {
    float: left;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    color: white;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    margin: 2px;
    background-color: grey;

    &.red {
      background-color: #dd1f20;
    }

    &.green {
      background-color: #67b627;
    }

    &.blue {
      background-color: #2962ff;
    }

    &.purple {
      background-color: #8c07b4;
    }

    &.brown {
      background-color: #854e14;
    }

    &.yellow {
      background-color: #ffe400;
      color: black;
    }

    &.orange {
      background-color: #cb5b00;
    }

    &.black {
      background-color: #000000;
    }
  }

  .result {
    border-top: 1px solid rgba(black, 0.1);
    margin-top: 6px;
    padding-top: 6px;
    max-width: 450px;

    .ball {
      position: relative;
      height: 30px;
      width: 30px;
      line-height: 30px;
      font-size: 12px;

      .bonus {
        position: absolute;
        display: block;
        top: 18px;
        left: 18px;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background-color: black;

        &:after {
          content: "*";
          font-size: 22px;
          line-height: 22px;
          text-align: center;
          color: #fff800;
        }
      }
    }
  }
</style>
