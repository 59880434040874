import types from './mutationTypes';

export default {
  [types.SET_COLUMN_SIZE](state, size) {
    state.columnSize = size;
  },
  [types.SET_GAME](state, game) {
    state.game = game;
  },
  [types.SET_PARAMS](state, params) {
    state.params = params;
  },
  [types.SET_PAYLINES](state, paylines) {
    state.paylines = paylines;
  },
  [types.SET_ROUND](state, round) {
    state.round = round;
  },
  [types.SET_SYMBOL_SIZE](state, size) {
    state.symbolSize = size;
  },
  [types.SET_VERSION_TYPE](state, type) {
    state.versionType = type;
  },
};
