<template>
  <div id="app"
       class="app">
    <RoundPreview />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { queryStringParser } from '@/utility';
import isMobile from 'ismobilejs';
import RoundPreview from '@/layouts/RoundPreview';

export default {
  name: 'App',
  components: {
    RoundPreview,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    ...mapActions([
      'getRoundDetails',
      'setRoundParams',
      'setVersionType',
    ]),
  },
  beforeMount() {
    this.getRoundDetails();
  },
  created() {
    this.isMobile = isMobile().any;
    const type = this.isMobile ? 'mobile' : 'desktop';
    this.setVersionType(type);

    const params = queryStringParser.parse(window.location.search) || {};
    this.setRoundParams(params);
  },
};
</script>

<style lang="scss">
  html,
  body {
    padding: 0;
    margin: 0;
  }

  #app {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
