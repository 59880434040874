<template>
  <div class="slotWinLine"
       id="slotWinLine"
       ref="slotWinLine">
  </div>
</template>

<script>
import * as PIXI from 'pixi.js';
import { each } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'SlotWinLines',
  computed: {
    ...mapGetters([
      'columnSize',
      'paylines',
      'symbolSize',
      'winLineSettings',
      'winlines',
    ]),
  },
  data() {
    return {
      containerHeight: null,
      containerWidth: null,
      pixiApp: null,
      pixiGraphics: null,
    };
  },
  methods: {
    calculateElementsMetrics() {
      const reelsContainer = document.getElementById('slotReelsWrapper');
      this.containerWidth = reelsContainer.getBoundingClientRect().width;
      this.containerHeight = reelsContainer.getBoundingClientRect().height;
      this.createPixiApp();
    },
    createLineStyle() {
      const {
        alpha,
        color,
        cap,
        join,
      } = this.winLineSettings;
      return {
        width: this.containerWidth * 0.01,
        color,
        alpha,
        join,
        cap,
      };
    },
    createPixiApp() {
      const winLinesContainer = document.getElementById('slotWinLine');
      this.pixiApp = new PIXI.Application({
        width: this.containerWidth,
        height: this.containerHeight,
        antialias: true,
        backgroundAlpha: 0,
      });
      this.pixiGraphics = new PIXI.Graphics();
      winLinesContainer.appendChild(this.pixiApp.view);
    },
    drawLines(winline) {
      const startingPosition = this.getStartPosition(winline.matrix[0]);
      this.pixiGraphics.lineStyle(this.createLineStyle());
      this.pixiGraphics.moveTo(startingPosition.x, startingPosition.y);
      each(winline.matrix, (matrix, index) => {
        if (index === 0) return;
        const matrixCoordinates = this.getMatrixPosition(matrix);
        this.pixiGraphics.lineTo(matrixCoordinates[0], matrixCoordinates[1]);
      });
      this.pixiGraphics.endFill();
      return this.pixiGraphics;
    },
    getMatrixPosition(matrix) {
      return [
        ((matrix[0] * this.columnSize.width) - (this.columnSize.width / 2)) + matrix[0],
        ((this.symbolSize.height * matrix[1]) - (this.symbolSize.height / 2)),
      ];
    },
    getStartPosition(position) {
      const x = this.columnSize.width / 2;
      const y = (this.symbolSize.height * position[1]) - (this.symbolSize.height / 2);

      return {
        x,
        y,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateElementsMetrics();
      each(this.winlines, (item) => {
        const winline = this.paylines[item.paylineIndex];
        this.pixiApp.stage.addChild(this.drawLines(winline));
      });
    });
  },
};
</script>

<style scoped lang="scss">
  .slotWinLine {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
  }
</style>
