<template>
  <div class="slotDisplayContainer"
       :class="[{loadingBackground: !isSizeSet}]">
    <div class="slotReelsWrapper"
         id="slotReelsWrapper"
         ref="reelsContainer"
         :style="reelsBackground">
      <SlotReel :key="reelIndex"
                :index="reelIndex"
                :symbols="getReelSymbols(reelIndex)"
                v-for="(reel, reelIndex) in symbols">
      </SlotReel>
    </div>
    <SlotWinLines v-if="hasRoundWinlines && reelColumnWidth"
                  :key="reelColumnWidth" />
    <div class="loader"
         v-if="!isSizeSet">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { has } from 'lodash';
import SlotReel from '@/components/SlotReel';
import SlotWinLines from '@/components/SlotWinLines';

export default {
  name: 'SlotReels',
  components: {
    SlotReel,
    SlotWinLines,
  },
  computed: {
    ...mapGetters([
      'assetUrl',
      'assets',
      'columnSize',
      'paylines',
      'symbolSize',
      'symbols',
      'winlines',
    ]),
    backgroundImage() {
      return {
        backgroundImage: this.assetUrl('reels_background'),
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      };
    },
    hasRoundWinlines() {
      return this.paylines && this.winlines;
    },
    isSizeSet() {
      return this.columnSize && this.symbolSize;
    },
    reelsBackground() {
      return has(this.assets, 'reels_background') ? this.backgroundImage : false;
    },
    reelColumnWidth() {
      return this.columnSize?.width;
    },
  },
  methods: {
    getReelSymbols(reelIndex) {
      return this.symbols[reelIndex];
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../style/mixins/loader";
  @import "../style/mixins/spin";

  .slotDisplayContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .slotReelsWrapper {
      width: 100%;
      display: flex;
      padding: 4px;
    }

    &.loadingBackground {
      background: grey;
    }
  }

  .loader {
    position: absolute;
    top: 38%;
    left: 42%;
    @include loader(40px, 2px, #ffeb3b, 2s);
  }
</style>
