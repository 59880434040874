import { roundApi } from '@nsftx/casino-client-sdk/src/api';
import { paylinesMatrixCreator } from '@/utility';
import types from './mutationTypes';

export default {
  async getRoundDetails({ commit, getters }) {
    const { roundId, tenantGameId } = getters.params;

    const response = await roundApi.getRoundDetails(roundId, tenantGameId);

    if (response.success) {
      const { game, round } = response.details;
      const { paylineSet } = game.config;

      if (paylineSet) {
        const paylines = paylineSet ? paylineSet.paylines : [];
        const paylinesMatrix = paylinesMatrixCreator.createPaylinesMatrix(paylines);
        commit(types.SET_PAYLINES, paylinesMatrix);
      }

      commit(types.SET_GAME, game);
      commit(types.SET_ROUND, round);
    }
  },
  setColumnSize({ commit }, size) {
    commit(types.SET_COLUMN_SIZE, size);
  },
  setSymbolSize({ commit }, size) {
    commit(types.SET_SYMBOL_SIZE, size);
  },
  setRoundParams({ commit }, params) {
    commit(types.SET_PARAMS, params);
  },
  setVersionType({ commit }, type) {
    commit(types.SET_VERSION_TYPE, type);
  },
};
