<template>
  <div class="home"
       v-if="gameType">
    <component :is="activeComponent" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SlingshotBets from '@/components/SlingshotBets';
import SlotReels from '@/components/SlotReels';

export default {
  name: 'home',
  components: {
    SlingshotBets,
    SlotReels,
  },
  computed: {
    ...mapGetters([
      'gameType',
    ]),
    activeComponent() {
      return this.gameType === 'Slot' ? 'SlotReels' : 'SlingshotBets';
    },
  },
};
</script>
